import React from 'react'
import "./header.css"
import 
 {BsFillBellFill, BsFillEnvelopeFill, BsPersonCircle, BsSearch, BsJustify}
 from 'react-icons/bs'

function Header({OpenSidebar,handleLogout}) {
  return (
    <header className='header'>
        <div className='menu-icon'>
            <BsJustify className='icon' onClick={OpenSidebar}/>
        </div>
        <div className='header-right'>
            <button className='logoutBtn' onClick={handleLogout}>Logout</button>
        </div>
    </header>
  )
}

export default Header