import React, {useContext} from 'react'
import VisaContext from "../../../context/visa-context.js";
import MyLoader from "../../Loader/MyLoader.jsx";
import { MdDownloadForOffline } from "react-icons/md";

const TmsFormWorkOrLang = () => {
    const ctxApi = useContext(VisaContext); 
    console.log("ctxApi.tmsForm",ctxApi.tmsForm)


    const downloadFormFile = async (filename) => {
        try {
          const response = await fetch(`https://backend-visa2.vercel.app/tmsApi/download-FromFile?filename=${filename}`);
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
      
          // Check content-type to ensure it's JSON
          const contentType = response.headers.get("content-type");
          if (!contentType || !contentType.includes("application/json")) {
            const text = await response.text();
            throw new Error(`Expected JSON, but got: ${text}`);
          }
      
          const data = await response.json();
          const downloadURL = data.downloadURL;  
          const link = document.createElement("a");
          link.href = downloadURL;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error("Error downloading the file:", error);
        }
      };

  return (
    <>
      <main className="main-container">
      {ctxApi.isLoading && <MyLoader />}
      <div className="main-title">
        <h3 style={{ color: "#13e313", fontSize: "25px" }}>The Migration School</h3>
      </div>      
      <div className="userpage">
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Mobile NO.</th>
              <th>Email</th>
              <th>File</th>
            </tr>
          </thead>
          <tbody>
            {ctxApi.tmsForm.map((user) => (
              <tr key={user._id}>               
                <td>{user.fName}</td>
                <td>{user.lName}</td>
                <td>{user.cNomber}</td>
                <td>{user.email}</td>
                <td>{user.fileName}    <span>
                        <MdDownloadForOffline
                          style={{
                            fontSize: "20px",
                            marginLeft: "6px",
                          }}
                          className="downloadIcons"
                          onClick={() => downloadFormFile(user.originalName)}
                        />
                      </span> </td>              
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
    </>
  )
}

export default TmsFormWorkOrLang;
