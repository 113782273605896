import { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Header from './Component/common/Header/Header';
import Sidebar from './Component/common/Sidebar/Sidebar';
import Home from './Component/pages/Home/Home';
import LoginPage from './Component/pages/login/LoginPage';
import User from './Component/pages/UserPage/User';
import UserDetails from './Component/pages/UserPage/UserDetails';
import UserDocuments from './Component/pages/UserPage/UserDocuments';
import Application from './Component/pages/UserPage/GetVisa/Application';
import UserPayMent from './Component/pages/UserPage/UserPayMent';
import UserDash from './Component/pages/UserDashboard/UserDash';
import UserApplicatons from './Component/pages/UserDashboard/UserApplicatons';
import Freelancers from './Component/pages/UserPage/Freelancers';
import TmsDatas from './Component/pages/TMS/TmsDatas';
import WorkVisaDetails from './Component/pages/WorkVisa/workVisaDetails/WorkVisaDetails';
import WorkVisaApplication from './Component/pages/WorkVisa/workVisaApplicatons/workVisaApplication';
import WorkVisaDocuments from './Component/pages/WorkVisa/workVisaDocuments/WorkVisaDocuments';
import AllWorkVisaApplications from './Component/pages/UserPage/GetVisa/AllWorkVisaApplications';
import AddOnsData from './Component/pages/UserPage/AddOnsData';
import TmsFormWorkOrLang from './Component/pages/TMS/TmsFormWorkOrLang';
function App() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const storedToken = localStorage.getItem("admin-token");
    if (storedToken) {
      setIsLogin(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLogin(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("admin-token");
    setIsLogin(false);
  };

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  return (
    <BrowserRouter>
      {isLogin ? (
        <LoggedInRoutes
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
          handleLogout={handleLogout}
        />
      ) : (
        <Routes>
          <Route path='/' element={<LoginPage onLogin={handleLogin} />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

function LoggedInRoutes({ openSidebarToggle, OpenSidebar, handleLogout }) {
  const navigate = useNavigate();

  const onLogout = () => {
    handleLogout();
    navigate('/');
  };

  return (
    <div className='grid-container'>
      <Header OpenSidebar={OpenSidebar} handleLogout={onLogout} />
      <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/user' element={<User />} />
        <Route path='/user-details' element={<UserDetails />} />
        <Route path='/userDocuments' element={<UserDocuments />} />
        <Route path='/applications' element={<Application />} />
        <Route path='/userpayment' element={<UserPayMent />} />
        <Route path='/userdashboard' element={<UserDash />} />
        <Route path='/userapplication' element={<UserApplicatons />} />
        <Route path='/freelancer' element={<Freelancers />} />
        <Route path='/tmsdata' element={<TmsDatas />} />
        <Route path='/worvisaData' element={<WorkVisaDetails />} />
        <Route path='/worvisaApplication' element={<WorkVisaApplication />} />
        <Route path='/workvisaDocuments' element={<WorkVisaDocuments />} />
        <Route path='/allWorkvisaApplications' element={<AllWorkVisaApplications />} />
        <Route path='/addOnsData' element={<AddOnsData />} />
        <Route path='/tmsWorkPermitOrLang' element={<TmsFormWorkOrLang />} />
        {/* <Route path='*' element={<Navigate to="/" />} /> */}
      </Routes>
    </div>
  );
}

export default App;

